import Batch from "@/entities/Batch";

export default {

    methods: {
        getBatchByShipment: async function (shipment, version = null, isBackground = false) {
            if (null == shipment || !shipment.isBatch()) {
                return null;
            }

            return this.getBatchById(shipment.getBatchId(), version, isBackground);
        },

        getBatchById: function (batchId, version = null, isBackground = false) {
            let that = this;

            if (!isBackground) {
                this.queueJob();
            }
            return this.axios.get(this.$store.state.config.getBatchUri(batchId, version)).then(
                response => {
                    let content = response.data;
                    if (!isBackground) {
                        that.completeJob();
                    }
                    return Promise.resolve(new Batch(content.data));
                }
            ).catch(
                error => {
                    console.warn(error);
                    if (!isBackground) {
                        that.completeJob();
                    }
                    return Promise.reject(null)
                }
            );
        }
    }
}