<template>
  <div>
    <div v-if="isCollecting" class="d-block col-md-12">
      <h3 v-show="batchConfirmed > 0" class="text-success mb-0 font-weight-semibold">
        {{$t('frontoffice.drivers.collect.summary.confirmed', {nr: batchConfirmed})}}
      </h3>
      <h3 v-show="batchPartial > 0" class="text-warning mb-0 font-weight-semibold">
        {{$t('frontoffice.drivers.collect.summary.partial', {nr: batchPartial})}}
      </h3>
      <h3 v-show="batchUnconfirmed > 0" class="text-danger mb-0 font-weight-semibold">
        {{$t('frontoffice.drivers.collect.summary.unconfirmed', {nr: batchUnconfirmed})}}
      </h3>
    </div>
    <div v-if="isDelivery" class="d-block col-md-12">
      <h3 v-show="orderConfirmed > 0" class="text-success mb-0 font-weight-semibold">
        {{$t('frontoffice.drivers.ship.summary.confirmed', {nr: orderConfirmed})}}
      </h3>
      <h3 v-show="orderPartial > 0" class="text-warning mb-0 font-weight-semibold">
        {{$t('frontoffice.drivers.ship.summary.partial', {nr: orderPartial})}}
      </h3>
      <h3 v-show="orderUnconfirmed > 0" class="text-danger mb-0 font-weight-semibold">
        {{$t('frontoffice.drivers.ship.summary.unconfirmed', {nr: orderUnconfirmed})}}
      </h3>
    </div>
  </div>
</template>

<script>
export default {
  name: "DeliveryNoteStatusCard",
  props: {
    deliveryNote: Object,
    isCollecting: {
      type: Boolean,
      default: false,
    },
    isDelivery: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    batchConfirmed: function () {
      return this.deliveryNote.getBatchShipments().filter((shipment) => shipment.isCollected()).length;
    },
    batchUnconfirmed: function () {
      return 0;
    },
    batchPartial: function () {
      return 0;
    },
    orderConfirmed: function () {
      return 0;
    },
    orderUnconfirmed: function () {
      return 0;
    },
    orderPartial: function () {
      return 0;
    }
  },
}
</script>

<style scoped>

</style>