<template>

  <OrderDetailsModal :order-id="order.getId()" v-if="orderDetailsOpen && withDetailsModal" @closemodal="orderDetailsOpen = false" />

  <Card  class="cursor-pointer" :card-body-style="isButtonClicked ? 'complete-bg' : ''" :with-header="false" :with-footer="false" @click="collectOrder">
    <template v-slot:default>
      <div class="d-flex align-items-center">
        <div class="flex-grow-1">
          <h3 class="p-0 m-0 font-weight-semibold"  ><span class="flex-grow-1">{{ $t('orders.edit.order_number', {number: order.getId()}) }}</span></h3>
          <span class="d-block mt-2">{{$t('orders.edit.weight', {bulk: formatQty(order.getTotalBulk()), pack: formatQty(order.getTotalPacked())})}}</span>
          <span class="d-block mb-2">{{$t('orders.edit.products_total', {total: order.getItemsWithQty().length})}}</span>
        </div>
        <i class="ml-2" :class="{ 'icon-checkmark-circle2 text-success': isButtonClicked, 'icon-radio-unchecked text-grey-300 opacity-50': !isButtonClicked }" style="font-size: 2em" ></i>
      </div>
      <div class="text-danger-400" v-if="!hasDocuments && withDocumentsWarning">{{ $t('orders.edit.no_documents_warning', {email: 'contact@verdefood.co'}) }}</div>
      <Button v-if="withDetailsModal" @click="viewDetails($event)" class="mt-2 mb-sm-0 w-100 w-sm-auto mx-sm-auto"
              name="orders.edit.view_details" type="show"/>
      <!--
      <span v-if="!isComplete" class="d-block text-muted">*{{$t('frontoffice.drivers.info.order_click')}}</span>
      <span v-else class="d-block">*{{$t('frontoffice.drivers.info.order_no_click')}}</span>
      -->
    </template>
    <!--
    <template v-slot:footer>
      <Button v-if="!isButtonClicked" class="ml-auto w-100" @click="collectOrder" type="confirm" :name="$t('frontoffice.drivers.buttons.order_collect')" />
      <Button v-else class="ml-auto w-100" @click="collectOrder" type="delete" :name="$t('frontoffice.drivers.buttons.order_not_collected')" />
    </template>
    -->
  </Card>
</template>

<script>
import Card from "@/components/elements/Card";
import Button from "@/components/elements/Button";
import {NumbersMixin} from "@/mixins/GeneralMixin";
import OrderDetailsModal from "@/components/order/frontoffice/OrderDetailsModal";

export default {
  name: "OrderCard",
  components: {
    OrderDetailsModal,
    Button,
    Card},
  emits: ['click'],
  mixins: [NumbersMixin],
  props: {
    order: Object,
    withDetailsModal: {
      type: Boolean,
      default: false
    },
    withDocumentsWarning: {
      type: Boolean,
      default: false
    },
    isComplete: {
      type: Boolean,
      default: false,
    }
  },
  data: function () {
    return {
      isButtonClicked: this.isComplete,
      orderDetailsOpen: false
    }
  },
  computed: {
    hasDocuments() {
      if (!this.order) return false;
      return this.order.getInvoice() && this.order.getInvoice().length;
    }
  },
  methods: {
    collectOrder: function () {
      this.isButtonClicked = !this.isButtonClicked;
      this.$emit('click');
    },
    viewDetails: function($event) {
      $event.stopPropagation();
      this.orderDetailsOpen=true;
    }
  }
}
</script>

<style scoped>
.clickable {
  cursor: pointer;
}
</style>
