<template>
  <div class="d-block col-md-12">
    <h5 v-show="confirmed > 0" class="text-success mb-0 font-weight-semibold">
      {{$t('frontoffice.drivers.summary.confirmed', {nr: confirmed})}}
    </h5>
    <h5 v-show="unconfirmed > 0" class="text-danger mb-0 font-weight-semibold">
      {{$t('frontoffice.drivers.summary.unconfirmed', {nr: unconfirmed})}}
    </h5>
  </div>
</template>

<script>
export default {
  name: "ShipmentBatchStatusCard",
  props: {
    orders: Object,
  },
  computed: {
    confirmed: function () {
      return this.orders.getItems().filter(order => order.checkIsOrderCollected()).length;
    },
    unconfirmed: function () {
      return this.orders.getSize() - this.confirmed;
    },
  },
}
</script>

<style scoped>

</style>