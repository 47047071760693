<template>
  <ConfirmationModal :with-prefix="false" @closemodal="closeConfirmation" @confirmmodal="confirmed" v-show="needsConfirmation" :action="$t(confirmationType, {nr: unconfirmedOrders})" />
  <Modal v-if="!isLoading && showSuccessModal && !dismissSuccessModal" :dismissible="false">
    <template v-slot:default>
      <h3 class="d-block text-center">{{ $t('frontoffice.drivers.finish.complete') }}.</h3>
      <h3 class="d-block text-right">{{ $t('frontoffice.drivers.finish.thanks') }}.</h3>
    </template>
  </Modal>

  <div v-if="deliveryNote" class="verde-farmer-top">
    <Card :with-header="false" :with-footer="false" class="bg-verde">
      <template v-slot:default>
        <h3 class="text-center">{{title}}</h3>
        <DriverDetails v-if="deliveryNote" :driver="deliveryNote.getDriver()" :show-contact="false"/>
        <div class="font-size-md mt-2">
          <div class="d-flex align-items-center">
            <i class="icon-calendar mr-3"></i>
            <div class="flex-grow-1" v-if="isCollecting">
              {{$t('frontoffice.drivers.collect.collect_day')}}
              <DayTimeEstimation class="d-inline" :moment="activeCycle && activeCycle.getCollectBatch()" />
            </div>
            <div class="flex-grow-1" v-else>
              {{$t('frontoffice.drivers.ship.ship_day')}}
              <DayTimeEstimation class="d-inline" :moment="activeCycle && activeCycle.getDeliverOrder()" />
            </div>
          </div>
        </div>
      </template>
    </Card>

    <div class="my-4" v-if="showActions">
      <Button v-if="isCollecting" @click="canShowMainContent=true" class="mb-2 mb-sm-0 w-100 w-sm-auto mx-sm-auto"
              name="frontoffice.drivers.collect.start" type="confirm"/>
      <Button v-if="isDelivery" @click="canShowMainContent=true" class="mb-2 mb-sm-0 w-100 w-sm-auto mx-sm-auto" name="frontoffice.drivers.ship.start" type="confirm"/>
    </div>

    <div v-if="canShowMainContent || showSuccessModal">
      <div v-if="isCollecting && batchOptions.length > 0" class="card p-2 text-center bg-verde">
        <div class="row text-center justify-content-center">
          <div class="col-sm-5 col-md-3 d-block align-self-center"><h2 class="mb-0 font-weight-semibold">
            {{ $t('frontoffice.drivers.collect.batch') }}</h2></div>
          <div class="col-sm-5 col-md-5 d-block align-self-center">
            <SelectInput class="mb-0 verde" @input="getBatch" :options="batchOptions"
                         :selected="batch ? batch.getId() : ''"/>
          </div>
        </div>
      </div>
      <div v-if="isDelivery && orderOptions.length > 0" class="card p-2 text-center bg-verde">
        <div class="row text-center justify-content-center">
          <div class="col-sm-5 col-md-3 d-block align-self-center"><h2 class="mb-0 font-weight-semibold">
            {{ $t('frontoffice.drivers.ship.order') }}</h2></div>
          <div class="col-sm-5 col-md-5 d-block align-self-center">
            <SelectInput class="mb-0 verde" @input="getOrder" :options="orderOptions"
                         :selected="order ? order.getId() : ''"/>
          </div>
        </div>
      </div>

      <div class="d-flex justify-content-between my-3">
        <Button v-bind:disabled="hasPrev" class="" @click="getPrev(false)" name="frontoffice.drivers.buttons.prev" type="prev"/>
        <Button v-bind:disabled="hasNext" class="" @click="getNext(false)" name="frontoffice.drivers.buttons.next" type="next"/>
      </div>

      <div v-if="shipment">
        <Card :with-footer="false" :with-header="false">
          <ShipmentDetails class="mb-4" :shipment="shipment" :batch="batch" :order="order"/>
          <BatchSummaryCard class="mb-2" v-if="shipment.isBatch()" :batch="batch" :with-order-nr="true" :with-count="false" :with-total="false" :total-batch-orders="batchOrders.getSize()" :with-total-batch-orders="true"/>
          <OrderSummaryCard v-if="shipment.isOrder()" :order="order" :with-order-weight="true" :with-order-nr="true" :has-name="false" :with-comments="false" :with-delivery-preference="true" :with-details-modal="true" :with-shipment-delivery-status="shipment"/>
        </Card>

        <h3 class="d-block text-center my-4" v-if="isCollecting && batchOrders.isLoaded()">{{$t('frontoffice.drivers.info.orders_instructions_collect')}}</h3>
        <!-- <h3 class="d-block text-center my-4" v-else>{{$t('frontoffice.drivers.info.orders_instructions_ship')}}</h3> -->

        <div v-if="isCollecting">
          <div v-if="batchOrders.isLoaded()">
            <OrderCard :with-details-modal="true" :with-documents-warning="true" :is-complete="order.checkIsOrderCollected()" @click="order.setIsOrderCollected(!order.checkIsOrderCollected())" :order="order" :key="order.getId()" v-for="order in batchOrders.getItems()"/>
          </div>
          <Loading v-else />
        </div>
        <Card :with-header="false" :with-footer="false">
          <template v-slot:default>
            <FormElement class="d-block d-lg-none">
              <CheckboxInput :disabled="showSuccessModal || shipment.isPhaseComplete()" label="frontoffice.drivers.info.is_packing_ok" class="d-block" :value="shipment.isPackingOk()" @update="shipment.is_packing_ok=$event" />
            </FormElement>
            <FormElement name="frontoffice.drivers.info.is_packing_ok" class="d-none d-lg-flex">
              <CheckboxInput :disabled="showSuccessModal || shipment.isPhaseComplete()" class="mt-2" :value="shipment.isPackingOk()" @update="shipment.is_packing_ok=$event" />
            </FormElement>
            <FormElement name="frontoffice.drivers.info.quality_issues">
              <textarea v-bind:disabled="showSuccessModal || shipment.isPhaseComplete()" class="font-weight-light w-100" v-model="shipment.qualityIssues"></textarea>
            </FormElement>
          </template>
        </Card>

        <div class="my-4">
          <ShipmentBatchStatusCard v-if="isCollecting" :orders="batchOrders"/>
        </div>

        <div class="my-4">
          <Button v-if="!shipment.isPhaseComplete() && !shipmentActionable.confirmation" @click="updateShipment" :name="shipmentActionable.button" type="confirm-mb-0" class="w-100 w-sm-auto mx-sm-auto"/>
          <Button v-else-if="!shipment.isPhaseComplete() && shipmentActionable.confirmation" @click="askConfirmation(shipmentActionable.confirmation)" :name="shipmentActionable.button" type="confirm-mb-0" class="w-100 w-sm-auto mx-sm-auto"/>
          <span v-else class="d-block text-success text-center mb-0">{{ $t(shipmentActionable.info) }} <i class="icon-checkmark2"></i></span>
        </div>

        <div class="my-4" v-if="canShowNextPrevButtons" >
            <DeliveryNoteStatusCard class="mb-2" :delivery-note="deliveryNote" :is-collecting="isCollecting"
                                    :is-delivery="isDelivery"/>
            <div class="d-flex justify-content-between">
              <Button v-bind:disabled="hasPrev" class="" @click="getPrev(false)" name="frontoffice.drivers.buttons.prev" type="prev"/>
              <Button v-bind:disabled="hasNext" class="" @click="getNext(false)" name="frontoffice.drivers.buttons.next" type="next"/>
            </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import DeliveryNote from "@/entities/DeliveryNote";
import Modal from "@/components/elements/Modal";
import Button from "@/components/elements/Button";
import SelectInput from "@/components/elements/SelectInput";
import Card from "@/components/elements/Card";
import BatchSummaryCard from "@/components/batch/frontoffice/BatchSummaryCard";
import OrderSummaryCard from "@/components/order/frontoffice/OrderSummaryCard";
import {ConfirmationModalMixin, LoadingModalMixin} from "@/mixins/ModalMixin";
import FormElement from "@/components/elements/FormElement";
import DeliveryNoteStatusCard from "@/components/deliverynote/frontoffice/DeliveryNoteStatusCard";
import ShipmentDetails from "@/components/deliverynote/frontoffice/ShipmentDetails";
import DriverDetails from "@/components/deliverynote/frontoffice/DriverDetails";
import CheckboxInput from "@/components/elements/CheckboxInput";
import OrderCard from "@/components/order/frontoffice/OrderCard";
import LoadableCollection from "@/entities/LoadableCollection";
import {ErrorHandlerMixin, NotificationsMixin} from "@/mixins/GeneralMixin";
import Loading from "@/components/elements/Loading";
import ShipmentBatchStatusCard from "@/components/batch/frontoffice/ShipmentBatchStatusCard";
import {ScrollMixin} from "@/mixins/ViewMixin";
import ConfirmationModal from "@/components/elements/ConfirmationModal";
import DayTimeEstimation from "@/components/elements/DayTimeEstimation";
import BatchMixin from "@/mixins/BatchMixin";
import Batch from "@/entities/Batch";
import OrderMixin from "@/mixins/OrderMixin";

export default {
  name: "DriverCollect",
  components: {
    DayTimeEstimation,
    Loading,
    OrderCard,
    CheckboxInput,
    DriverDetails,
    ShipmentDetails,
    DeliveryNoteStatusCard,
    FormElement,
    OrderSummaryCard,
    BatchSummaryCard,
    Card,
    SelectInput,
    Button,
    Modal,
    ShipmentBatchStatusCard,
    ConfirmationModal,
  },
  mixins: [LoadingModalMixin, ErrorHandlerMixin, ScrollMixin, NotificationsMixin, ConfirmationModalMixin, BatchMixin, OrderMixin],
  data() {
    return {
      deliveryNote: null,
      batch: null,
      order: null,
      shipment: null,
      batchOrders: new LoadableCollection(),

      canShowMainContent: false,
      dismissSuccessModal: false,
    }
  },
  beforeMount: function () {
    this.init();
  },
  computed: {
    confirmedOrders: function () {
      return this.batchOrders.isLoaded() && this.batchOrders.getItems().filter(order => order.checkIsOrderCollected()).length;
    },
    unconfirmedOrders: function () {
      return this.batchOrders.getSize() - this.confirmedOrders;
    },
    shipmentActionable: function () {
      if (this.isDelivery) {
        return {
          button: "frontoffice.drivers.buttons.ship",
          info: "frontoffice.drivers.info.shipped",
          confirmation: null,
        }
      }
      return {
        button: "frontoffice.drivers.buttons.collect",
        info: "frontoffice.drivers.info.collected",
        confirmation: "frontoffice.drivers.confirmation.collect"
      }
    },
    canShowNextPrevButtons: function () {
      return (this.isCollecting && this.batchOptions.length > 1) || (this.isDelivery && this.orderOptions.length > 1);
    },
    isCollecting: function () {
      return this.$router.currentRoute.value.name == 'frontoffice.breadcrumb.drivers.collect';
    },
    isDelivery: function () {
      return this.$router.currentRoute.value.name == 'frontoffice.breadcrumb.drivers.deliver';
    },
    showActions: function () {
      return !this.canShowMainContent && !this.showSuccessModal;
    },
    title: function () {
      if (!this.deliveryNote) {
        return '';
      }

      if (this.isCollecting) {
        return this.$i18n.t('frontoffice.drivers.collect.title'); // , {name: this.deliveryNote.getName()}
      }

      if (this.isDelivery) {
        return this.$i18n.t('frontoffice.drivers.ship.title'); //, {name: this.deliveryNote.getName()}
      }

      return "";
    },
    showSuccessModal: function () {

      if (!this.deliveryNote || !this.isDelivery) {
        return false;
      }

      return this.deliveryNote.getOrderShipmentsForDelivery().filter((shipment) => !shipment.isPhaseComplete()).length === 0;
    },
    activeCycle: function () {
      return this.$store.state.cycle;
    },
    orders: function () {
      return this.deliveryNote ? this.deliveryNote.getOrderShipmentsForDelivery() : [];
    },
    batches: function () {
      return this.deliveryNote ? this.deliveryNote.getBatchShipments() : [];
    },
    orderOptions: function () {
      let options = [];
      if (!this.deliveryNote) {
        return options;
      }
      let it = 0;
      this.deliveryNote.getOrderShipmentsForDelivery().map((shipment) => options.push({
            val: shipment.getOrder().getId(),
            txt: this.$i18n.t('frontoffice.drivers.ship.select_current', {
              current: (++it),
              total: this.deliveryNote.getOrderShipmentsForDelivery().length
            }),
          })
      );

      return options;
    },
    hasNext: function () {
      if (null === this.shipment) {
        return false;
      }

      if (this.isCollecting) {
        return this.deliveryNote.isLastBatchShipment(this.shipment);
      }

      if (this.isDelivery) {
        return this.deliveryNote.isLastOrderShipment(this.shipment);
      }

      return true;
    },
    hasPrev: function () {
      if (null === this.shipment) {
        return false;
      }

      if (this.isCollecting) {
        return this.deliveryNote.isFirstBatchShipment(this.shipment);
      }

      if (this.isDelivery) {
        return this.deliveryNote.isFirstOrderShipment(this.shipment);
      }

      return true;
    },
    batchOptions: function () {
      let options = [];
      if (!this.deliveryNote) {
        return options;
      }

      let it = 0;
      this.deliveryNote.getBatchShipments().map((shipment) => options.push({
            val: shipment.getBatchId(),
            txt: this.$i18n.t('frontoffice.drivers.collect.select_current', {
              current: (++it),
              total: this.deliveryNote.getBatchShipments().length
            }),
          })
      );

      return options;
    },
  },
  watch: {
    batch: {
      handler: function (newValue, oldValue) {
        if ((newValue !== null && oldValue === null) ||
            (oldValue !== null && newValue !== null)
        ) {
          this.getBatchOrders();
        }
      }
    },
  },
  methods: {
    init: function () {
      this.getDeliveryNote(true);
    },
    reset: function () {
      this.batch = null;
      this.order = null;
      this.shipment = null;
      this.batchOrders.unloadAndEmpty();
      this.canShowMainContent = false;
    },
    getBatchOrders: async function () {

      if (!this.batch) {
        return;
      }

      this.batchOrders.unloadAndEmpty();
      let promises = [];
      this.batch.getConfirmedOrders().forEach((orderId) => {
        promises.push(this.getOrderById(orderId, true));
      });

      this.batchOrders = new LoadableCollection(await Promise.all(promises));
      this.batchOrders.loaded();
    },
    onLoadingComplete() {
    },
    confirmed() {
      switch (this.confirmationType) {
        case "frontoffice.drivers.confirmation.collect":
          this.updateShipment();
          break;
      }
      this.closeConfirmation();
    },
    getBatch: async function (reference, id) {

      let shipment = this.deliveryNote.getShipmentForBatch(id);
      if (null == shipment) {
        return;
      }

      this.shipment = shipment;
      this.batch = await this.getBatchById(shipment.getBatchId(), Batch.VERSION_DELIVERY);
    },
    getOrder: function (reference, id) {
      let shipment = this.deliveryNote.getShipmentForOrder(id);
      this.shipment = shipment;
      this.order = shipment ? shipment.getOrder() : null;
    },
    getNext: async function (init = false) {
      if (!this.deliveryNote) {
        return;
      }

      let counter = 1;
      this.scrollToTop();
      if (this.isCollecting) {
        this.shipment = this.deliveryNote.getNextBatchShipment(init ? null : this.shipment, function (shipment) {
          return (!init && counter-- > 0) || (init && shipment && shipment.isCollected());
        });
        this.batch = this.shipment ? await this.getBatchByShipment(this.shipment, Batch.VERSION_DELIVERY) : null;
        return;
      }

      if (this.isDelivery) {
        this.shipment = this.deliveryNote.getNextOrderShipment(init ? null : this.shipment, function (shipment) {
          return (!init && counter-- > 0) || (init && shipment && shipment.isDelivered());
        });
        this.order = this.shipment ? this.shipment.getOrder() : null;
      }
    },

    getPrev: async function (init = false) {
      if (!this.deliveryNote) {
        return;
      }

      let counter = 1;
      this.scrollToTop();
      if (this.isCollecting) {
        this.shipment = this.deliveryNote.getPrevBatchShipment(this.shipment, function (shipment) {
          return (!init && counter-- > 0) || (init && shipment.isCollected());
        });
        this.batch = this.shipment ? await this.getBatchByShipment(this.shipment, Batch.VERSION_DELIVERY) : null;
        return;
      }

      if (this.isDelivery) {
        this.shipment = this.deliveryNote.getPrevOrderShipment(this.shipment, function (shipment) {
          return (!init && counter-- > 0) || (init && shipment.isDelivered());
        });
        this.order = this.shipment.getOrder();
      }
    },
    collectOrders: function() {
      let promises = [];

      if (this.batchOrders.isEmpty() || this.isDelivery) {
        return promises;
      }

      for (let order of this.batchOrders.getItems().filter((order) => order.checkIsOrderCollected())) {
        promises.push(this.axios.put(this.$store.state.config.getCollectOrderUri(order.getId())));
      }

      return promises;
    },
    updateShipment: function () {
      let that = this;

      let data = {
        'is_packing_ok': this.shipment.isPackingOk(),
      };

      if (this.shipment.getQualityIssues()) {
        data.quality_issues = this.shipment.getQualityIssues();
      }
      if (this.isCollecting) {
        data.collected = true;
      }
      if (this.isDelivery) {
        data.delivered = true;
      }

      this.queueJob();
      let collectingOrders = this.collectOrders();
      Promise.all(collectingOrders).then(() => {
        that.axios.put(that.$store.state.config.getShipmentUri(that.$route.params.noteId, that.shipment.getId()), data).then(
            response => {
              let content = response.data;
              that.deliveryNote = new DeliveryNote(content.data);
              that.deliveryNote.setLoaded(true);
              that.getNext();
            }
        ).catch(
            error => {
              console.warn(error);
            }
        ).finally(function () {
          that.completeJob();
          that.switchToDeliveryIfPossible();
        })
      }).catch(error => console.error(error));
    },
    getDeliveryNote: function (init = false) {
      let that = this;

      this.queueJob();
      this.reset();
      this.axios.get(this.$store.state.config.getDeliveryNoteUri(this.$route.params.noteId)).then(
          response => {
            let content = response.data;
            that.deliveryNote = new DeliveryNote(content.data);
            that.deliveryNote.setLoaded(true);
            that.switchToCollectingIfPossible();
            that.switchToDeliveryIfPossible();
          }
      ).catch(
          error => {
            console.warn(error);
          }
      ).finally(function () {
        that.getNext(true);
        that.completeJob(init);
      });
    },
    switchToCollectingIfPossible: function () {
      if (!this.isDelivery) {
        return;
      }

      if (!this.deliveryNote.isCollectionFinished()) {
        this.$router.push({name: 'frontoffice.breadcrumb.drivers.collect', params: {noteId: this.$route.params.noteId}});
        this.init();
      }
    },
    switchToDeliveryIfPossible: function () {
      if (!this.isCollecting) {
        return;
      }

      if (this.deliveryNote.isCollectionFinished()) {
        this.$router.push({name: 'frontoffice.breadcrumb.drivers.deliver', params: {noteId: this.$route.params.noteId}});
        this.init();
      }
    }
  }
}
</script>

<style scoped>

</style>
