
import Order from "@/entities/Order";

export default {

    methods: {
        getOrderByShipment: async function (shipment, isBackground = false) {
            if (null == shipment || !shipment.isOrder()) {
                return null;
            }

            return this.getOrderById(shipment.getOrderId(), isBackground);
        },

        getOrderById: function (orderId, isBackground = false) {
            let that = this;

            if (!isBackground) {
                this.queueJob();
            }

            return this.axios.get(this.$store.state.config.getOrderUri(orderId)).then(
                response => {
                    let content = response.data;
                    if (!isBackground) {
                        that.completeJob();
                    }
                    return Promise.resolve(new Order(content.data));
                }
            ).catch(
                error => {
                    console.warn(error);
                    if (!isBackground) {
                        that.completeJob();
                    }
                    return Promise.reject(null)
                }
            );
        }
    }
}