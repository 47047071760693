<template>
  <div class="d-lg-flex justify-content-lg-between">
    <User class="mb-2 mb-lg-0" :name="contact" />
    <Call class="mb-2 mb-lg-0" :telephone="telephone" :show="true" />
    <Address :address="address" :has-pin="true" />
  </div>
</template>

<script>
import Call from "@/components/elements/Call";
import Address from "@/components/elements/Address";
import User from "@/components/elements/User";

export default {
  name: "ShipmentDetails",
  components: {User, Address, Call},
  props: {
    shipment: Object,
    batch: Object,
    order: Object,
  },
  computed: {
    isOrder: function () {
      return this.shipment.isOrder();
    },
    isBatch: function () {
      return this.shipment.isBatch();
    },
    contact: function () {
      if (this.isOrder) {
        return this.order.getContact();
      }
      if (this.isBatch) {
        return this.batch.getFarm().getName();
      }
      return "";
    },
    telephone: function () {
      if (this.isOrder) {
        return this.order.getTelephone();
      }
      if (this.isBatch) {
        return this.batch.getFarm().getTelephone();
      }
      return "";
    },
    email: function () {
      if (this.isOrder) {
        return this.order.getEmail();
      }
      if (this.isBatch) {
        return this.batch.getFarm().getEmail();
      }
      return "";
    },
    address: function () {
      if (this.isOrder) {
        return this.order.getAddress();
      }
      if (this.isBatch) {
        return this.batch.getFarm().getAddress();
      }

      return null;
    }
  }
}
</script>

<style scoped>

</style>